import React, {useEffect} from "react"
import { Container, Typography, Grid, CssBaseline, makeStyles, withStyles} from "@material-ui/core"
import {Helmet} from "react-helmet/es/Helmet";

const social = [
  {
    alt: "Twitter",
    src: "./twitter.svg",
    href: "https://twitter.com/vejmartin"
  },
  {
    alt: "LinkedIn",
    src: "./linkedin.svg",
    href: "https://www.linkedin.com/in/vejmartin/"
  },
  {
    alt: "Medium",
    src: "./medium.svg",
    href: "https://medium.com/@vejmartin"
  },
  {
    alt: "GitHub",
    src: "./github.svg",
    href: "https://github.com/vejmartin"
  },
  {
    alt: "DEV",
    src: "./dev.svg",
    href: "https://dev.to/vejmartin"
  }
];

const ImageLink = ({src, alt, href}) => (
  <a href={href}>
    <img alt={alt} src={src} width="30px"
         style={{margin: "10px 20px"}}/>
  </a>
);

const useStyles = makeStyles(theme => ({
  title: {
    marginBottom: '10px',
    textAlign: 'center',
  },
  subtitle: {
    marginBottom: '30px',
    textAlign: 'center',
  },
  container: {
    // height: 'var(--vh100, 100vh)',
    height: '100%',
  },
  rootContainer: {
    height: '100%',
  }
}));

const GlobalCss = withStyles({
  '@global': {
    'html, body, #___gatsby, #gatsby-focus-wrapper': {
      height: '100%',
    },
  },
})(() => null);

const IndexPage = (props) => {
  const classes = useStyles(props);
  useEffect(() => {
    const update = () => {
      document.documentElement.style.setProperty('--vh100',
        `${window.innerHeight}px`);
    }

    update();

    window.addEventListener('resize', update);
    return () => window.removeEventListener('resize', update);
  }, []);

  return (
      <Container component="main" className={classes.rootContainer}>
        <GlobalCss />
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          className={classes.container}
        >

          <Grid item>
            <Typography
              variant="h3"
              className={classes.title}
            >Martin Vejdarski</Typography>
            <Typography
              variant="h5"
              className={classes.subtitle}
            >Software Engineer</Typography>

          </Grid>
          <Grid container justify="center" wrap="wrap" style={{filter: 'invert(100%)', display: 'flex', alignItems: 'center'}}>
            {social.map((props, index) => <ImageLink {...props} key={index}/>)}
          </Grid>

        </Grid>
      </Container>
  );
};

export default IndexPage
